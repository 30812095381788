import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import { Link } from "gatsby"
import media from "styled-media-query"

export const Section = styled.section`
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: ${props => props.theme.colors.greenLighter};
  padding-left: 15px;
  padding-right: 15px;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Header = styled.div``

export const Return = styled.p`
  display: flex;
  justify-content: space-between;
`

export const Image = styled.img`
  width: 20px;
  height: 15px;
  src: url(${props => props.src});
`

export const Text = styled.p`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
  color: ${props => props.theme.colors.greenDarker};
`

export const Symbol = styled.img`
  src: url(${props => props.src});
  width: 36px;
  height: 35px;
`

export const Container = styled(GridContainer)`
  background-color: ${props => props.theme.colors.greenLighter};
`

export const Back = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const Title = styled.p`
  margin-top: 45px;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.36;
  color: ${props => props.theme.colors.charcoal};
`

export const Content = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 30px;

  padding-bottom: 60px;
  background-color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  p {
    margin: 0 20px 35px 0;
    padding-left: 15px;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    margin: 55px 0px 15px 40px;
    padding-left: 15px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: #125332;
  }

  span {
    color: ${props => props.theme.colors.greenDark};
  }
`

export const Divisor = styled.hr`
  background-color: #d8d8d8;
`

export const Imagem = styled.img`
  margin-top: 70px;
  display: block;
  margin-left: calc(100vw - 308px);
  width: 308px;
  height: 105px;

  src: url(${props => props.src});
`

export const Simulator = styled.div`
  padding-top: 1px;
  padding-bottom: 45px;
  background-color: #125332;

  h1 {
    margin-right: 40px;
    font-size: 36px;
    font-weight: 800;
    line-height: 1.28;
    color: ${props => props.theme.colors.offWhite};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: ${props => props.theme.colors.offWhite};
  }
`

export const Button = styled(Link)`
  text-decoration: none;
  display: flex;
  border-radius: 10px;
  width: 327px;
  height: 56px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  p {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }
`

export const Contact = styled.div`
  margin-top: 70px;

  h1 {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.36;
    color: ${props => props.theme.colors.charcoal};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.charcoal};
  }

  strong {
    font-weight: 800;
  }
`

export const Phone = styled.div`
  h2 {
    margin-left: 15px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.8px;
    color: ${props => props.theme.colors.greenDarker};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.greenDarker};
  }
`
export const Center = styled.div`
  display: flex;
  justify-content: center;
`
