import React from "react"

import FAQ from "../../images/faq.png"
import Logos from "../../images/logos.png"
import ZapIcon from "../../images/group-11.png"
import PlayStore from "../../images/playstore.png"
import AppStore from "../../images/appstore.png"

import Header from "../../components/header"
import HeaderMobile from "../../components/headerMobile"
import Question from "../../components/question"

import { Link } from "gatsby"

import {
  Container,
  Content,
  Center,
  Imagem,
  Section,
  Simulator,
  SimulatorContainer,
  SimulatorContent,
  SimulatorImage,
  Contact,
  ContactContent,
  Crisp,
  Zap,
  Button,
  Phone,
  CallToActionContent,
  PlayStoreImage,
} from "./styles"

export default function Faq() {
  return (
    <Container fluid>
      <Header title="Como nós podemos te ajudar?" />
      <HeaderMobile title="Como nós podemos te ajudar?" />
      <Content>
        <Center>
          <Question title="Quem é a Veriza?">
            <p>
              Veriza é uma plataforma revolucionária de empréstimos, nós usamos
              a tecnologia ao seu favor. Trabalhamos com crédito em grupo: você
              se conecta com mais alguns amigos e juntos solicitam o crédito.
              Dessa forma, a Veriza consegue trabalhar com taxas melhores,
              análise de crédito flexível e tudo isso sem a necessidade do seu
              imóvel ou veículo como garantia. Em todo o processo estamos
              disponíveis para te auxiliar. A Veriza tem sua sede em Brasília,
              no coração do país.
            </p>
          </Question>
          <Question title="A Veriza é um banco?">
            <p>
              A Veriza não é um banco. Nós somos uma plataforma digital que
              oferece crédito simples para pessoas que precisam. Operamos junto
              a instituições financeiras que emitem o contrato de empréstimo
              conforme a sua solicitação. Sendo assim, somos um correspondente
              bancário. Conte conosco para obter o seu crédito de forma mais
              acessível e simples. Estamos juntos!
            </p>
          </Question>
          <Question title="Como funciona o crédito em grupo?">
            <p>
              Formando grupos de 3 pessoas vocês estarão aptos a solicitar
              crédito e assim conseguir taxas melhores, essas pessoas serão
              corresponsáveis pelo empréstimo, caso alguém não pague, as outras
              pessoas ficarão responsáveis pelo pagamento das parcelas
              restantes.
            </p>
          </Question>
          <Question title="O que é um correspondente bancário?">
            <p>
              Correspondente bancário é um estabelecimento ou pessoa jurídica
              que pode atender os clientes em nome de um banco, oferecendo
              alguns serviços bancários específicos.
            </p>
          </Question>
        </Center>
        <Imagem src={FAQ} />
        <SimulatorContainer>
          <Simulator>
            <SimulatorContent>
              <h1>Vemos no crédito uma solução.</h1>
              <p>
                A Veriza nasceu para ajudar os milhões de microempreendedores
                brasileiros que não têm acesso a crédito. Inicie a sua
                solicitação.
              </p>
              <Button to="/">
                <p>Eu quero simular</p>
              </Button>
            </SimulatorContent>
            <SimulatorImage src={Logos}></SimulatorImage>
          </Simulator>
        </SimulatorContainer>
        <Contact>
          <CallToActionContent>
            <h1>Baixe já o app Veriza</h1>
            <PlayStoreImage
              src={PlayStore}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=br.com.veriza.app&hl=pt_BR",
                  "_blank"
                )
              }
            />
            <PlayStoreImage
              src={AppStore}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/veriza/id1550168186",
                  "_blank"
                )
              }
            />
          </CallToActionContent>
          {/* <ContactContent>
            <h2>Tem dúvidas?</h2>
            <Crisp>
              Estamos ao seu dispor! Fale conosco através do nosso{" "}
              <strong> Atendimento Online</strong> clicando no botão de chat no
              canto inferior da tela.
            </Crisp>
            <Phone>
              <p>
                Ou mande um Olá no <strong>Whatsapp</strong>!
              </p>
              <h2>
                <span>61</span> 98359-6111{" "}
                <Zap
                  src={ZapIcon}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=556183699736&text=",
                      "_blank"
                    )
                  }
                />
              </h2>
            </Phone>
          </ContactContent> */}
        </Contact>
      </Content>
    </Container>
  )
}
