import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import ZapButton from "../components/ZapButton"

// Sections
import FAQ from "../sections/faq"
import Footer from "../sections/footer"

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <FAQ />
    <Footer />
    <ZapButton />
  </Layout>
)

export default FaqPage
