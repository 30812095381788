import React from "react"
import Logo from "../../images/symbol.png"
import { navigate } from "gatsby"

import {
  Section,
  Header,
  Return,
  Back,
  Symbol,
  Text,
  Title,
} from "./styles"

export default function HeaderMobile({ title }) {
  return (
    <Section>
      <Header>
        <Return>
          <Back onClick={() => window.history.back()}>
            <Text>Voltar</Text>
          </Back>
          <Symbol src={Logo} />
        </Return>
        <Title>{title}</Title>
      </Header>
    </Section>
  )
}
