import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Menu, Back, Logo, FAQ, Title, LinksContainer } from "./styles"

export default function Header({ title }) {
  const { Veriza } = useStaticQuery(graphql`
    query {
      Veriza: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Menu>
        <Back to="/">
          <Logo fluid={Veriza.childImageSharp.fluid} />
        </Back>
        <LinksContainer>
          <Back to="/">
            <FAQ>Início</FAQ>
          </Back>
          <Back to="/faq">
            <FAQ><span>Perguntas frequentes</span></FAQ>
          </Back>
          <Back to="http://blog.veriza.com.br/">
            <FAQ>Blog Veriza</FAQ>
          </Back>
        </LinksContainer>
      </Menu>
      <Title>{title}</Title>
    </Container>
  )
}
