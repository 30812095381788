import styled from 'styled-components';
import media from "styled-media-query"

export const Zap = styled.img`
  width: 55px;
  height: 55px; 
  position: fixed; 
  bottom: 35px; 
  right: 30px; 
  cursor: pointer;

  ${media.lessThan("medium")`
    width: 45px;
    height: 45px; 
  `}
`;
