import React from 'react';

import ZapIcon from "../../images/group-11@3x.png"

import { Zap } from './styles';

function ZapButton() {
  return (
    <Zap
      src={ZapIcon}
      onClick={() =>
        window.open(
          "https://api.whatsapp.com/send?phone=556183699736&text=",
          "_blank"
        )
      }
    />
  );
}

export default ZapButton;