import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  margin-top: 50px;
  width: 100%;
 
  ${media.lessThan("medium")`
    padding-left: 27px;
    padding-right: 27px;
  `}

  ${media.greaterThan("medium")`
    width: 644px;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: ${props => props.color};
  opacity: 0.8;
`

export const Text = styled.div`
  margin-top: 30px;

  p {
    margin-left: -15px;
    margin-right: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.charcoal};
  }
`

export const Button = styled.img`
  width: 20px;
  height: 20px;
  src: url(${props => props.src});
`

export const Divisor = styled.hr`
  margin-top: 35px;
  background-color: #d8d8d8;
`
