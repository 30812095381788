import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import { Link } from "gatsby"
import media from "styled-media-query"

export const Container = styled(GridContainer)`
  background-color: ${props => props.theme.colors.greenLighter};
`

export const Section = styled.section`
  padding-top: 45px;
  padding-bottom: 45px;
`

export const Return = styled.p`
  display: flex;
  justify-content: space-between;
`

export const Back = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  width: 20px;
  height: 15px;
  src: url(${props => props.src});
`

export const Symbol = styled.img`
  src: url(${props => props.src});
  width: 36px;
  height: 35px;
`

export const Text = styled.p`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.greenDark};
`

export const Title = styled.p`
  margin-right: 30px;
  margin-top: 45px;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.36;
  color: ${props => props.theme.colors.charcoal};
`

export const Content = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 30px;

  padding-bottom: 60px;
  background-color: #ffffff;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  p {
    ${media.greaterThan("medium")`
      padding-left: 0px;
    `}

    margin-bottom: 20px;
    margin-right: 35px;
    padding-left: 28px;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    ${media.greaterThan("medium")`
      padding-left: 0px;
    `}

    margin-top: 45px;
    margin-bottom: 15px;
    margin-right: 40px;
    padding-left: 28px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: #125332;
  }

  span {
    color: ${props => props.theme.colors.greenDark};
  }
`

export const Divisor = styled.hr`
  background-color: #d8d8d8;
`

export const Imagem = styled.img`
  margin-top: 70px;
  display: block;
  margin-left: calc(100vw - 308px);
  width: 308px;
  height: 105px;

  src: url(${props => props.src});

  ${media.greaterThan("medium")`
    display: none;
  `}
`
export const SimulatorContainer = styled.div`
  display: flex;
  background-color: #125332;
  width: 100%;
  position: relative;

  ${media.greaterThan("medium")`
    justify-content: center;
    height: 360px;
    margin-top: 150px;
  `}
`

export const Simulator = styled.div`
  padding-top: 1px;
  padding-bottom: 45px;
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.greaterThan("medium")`
    width: 1140px;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  `}
`

export const SimulatorContent = styled.div`
  width: 632px;
  z-index: 10;

  h1 {
    margin-right: 40px;
    font-size: 44px;
    font-weight: 800;
    line-height: 54px;
    color: ${props => props.theme.colors.offWhite};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-top: 20px;
    color: ${props => props.theme.colors.offWhite};
  }
`

export const SimulatorImage = styled.img`
  ${media.lessThan("medium")`
    display: none;
  `}  

  src: url(${props => props.src});
  align-self: flex-end;
  z-index: 0;
  width: 606px;
  height: 464px;
  position: absolute;
  right: 60px;
  bottom: 0;
`

export const Button = styled(Link)`
  ${media.greaterThan("medium")`
    margin: 42px 0 54px 0;
  `}

  text-decoration: none;
  display: flex;
  border-radius: 10px;
  width: 327px;
  height: 48px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  margin-top: 42px;

  p {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }

  :hover {
    background-color: ${props => props.theme.colors.mango};
    opacity: 0.5;
  }
`

export const Contact = styled.div`
  margin: 50px 0px;
  display: flex;
  ${media.greaterThan("medium")`
    justify-content: center;
    margin: 50px 90px;
  `}

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`
export const CallToActionContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  
  h1 {
    font-size: 41px;
    font-weight: 800;
    line-height: 1.36;
    width: 75%;
    color: ${props => props.theme.colors.charcoal};
  }
`

export const PlayStoreImage = styled.img`
  width: 189px;
  height: 55px;
  src: url(${props => props.src});
  margin-top: 20px;
  ${media.lessThan("medium")`
    margin-left: 25px;
  `}
    :hover {
    cursor: pointer;
  }
`

export const ContactContent = styled.div`
  h2 {
    font-size: 28px;
    font-weight: 800;
    line-height: 1.36;
    color: ${props => props.theme.colors.charcoal};
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.lessThan("medium")`
    margin-top: 50px;
  `}
`

export const Crisp = styled.p`
  max-width: 500px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  strong {
    font-weight: 800;
  }
`

export const Phone = styled.div`
  h2 {
    ${media.greaterThan("medium")`
      margin-left: 0px;
    `}

    margin-left: 28px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.8px;
    color: ${props => props.theme.colors.greenDark};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${props => props.theme.colors.charcoal};
  }

  span {
    font-size: 18px;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.greenDark};
  }
`
export const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Zap = styled.img`
  width: 25px;
  height: 25px;
  src: url(${props => props.src});
`
