import React, { useState } from "react"

import open from "../../images/open.png"
import closed from "../../images/closed.png"

import { Container, Header, Title, Button, Text, Divisor } from "./styles"

const Question = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <Header onClick={() => setIsOpen(!isOpen)}>
        <Title color={isOpen ? "#135434" : "#2c2d2c"}>{title}</Title>
        <Button src={isOpen ? open : closed}></Button>
      </Header>
      {isOpen && <Text>{children}</Text>}
      <Divisor />
    </Container>
  )
}

export default Question
