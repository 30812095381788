import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import media from "styled-media-query"

export const Container = styled.div`
  height: 448px;
  background-color: ${props => props.theme.colors.greenLighter};
  span {
    background-color: ${props => props.theme.colors.white};
  }

  ${media.lessThan("medium")`
    display: none;
  `}
`

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 80px 200px 95px 180px;
`

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 370px;
`

export const Back = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const Logo = styled(Img)`
  width: 220px;
  height: 52px;
`

export const FAQ = styled.p`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.greenDarker};
  span {
    font-weight: Bold;
    background-color: ${props => props.theme.colors.greenLighter};
  }
`

export const Title = styled.p`
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: ${props => props.theme.colors.greenDarker};
`
